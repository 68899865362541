import React from 'react';
import {Link} from 'gatsby';
import BlogSidebar from './BlogSidebar';
import img11 from '../../assets/images/blog/blog-img11.jpg';
import img12 from '../../assets/images/blog/blog-img12.jpg';
import user1 from '../../assets/images/user1.jpg';
import BlogContentHeader from './BlogContentHeader';
import WrapperStyle from './BlogDetailContent.style'
import BlogPrevNext from './BlogPrevNext';
import {TwitterShareButton, FacebookShareButton, FacebookIcon, TwitterIcon, LineShareButton, LineIcon} from 'react-share';
const BlogDetailsContent = (props) => {
    const {frontmatter, prev, next} = props;
    const articleUrl = 'https://retach.biz' + frontmatter.slug;
    return (
        <WrapperStyle>
        <section className="blog-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-1 col-md-12">
                    </div>
                    <div className="col-lg-10 col-md-12">
                        <div className="blog-details-desc">
                            <h1>{frontmatter.title}</h1>
                            <BlogContentHeader
                              slug={frontmatter.slug}
                              title={frontmatter.title}
                              tags={frontmatter.tags}
                              author={frontmatter.author}
                              createdAt={frontmatter.createdAt}
                              updatedAt={frontmatter.updatedAt}
                            />
                            <div className="article-image">
                                <img src={frontmatter.bannerImage.childImageSharp.fluid.src} alt="bb" />
                            </div>
                            <div style={{marginTop:'30px', marginBottom:'50px'}}>
                                <TwitterShareButton url={articleUrl} style={{width: '33%'}}>
                                    <TwitterIcon size={30} height={"50px"} width={'100%'} style={{backgroundColor:'#00aced'}}/>
                                </TwitterShareButton>
                                <FacebookShareButton url={articleUrl} style={{width: '33%'}} >
                                    <FacebookIcon size={30} height={"50px"} width={'100%'} style={{backgroundColor:'#3b5998'}} />
                                </FacebookShareButton>
                                <LineShareButton url={articleUrl} style={{width: '33%'}} >
                                    <LineIcon size={30} height={"50px"} width={'100%'} style={{backgroundColor:'#00b800'}}  />
                                </LineShareButton>
                            </div>
                            <div className="article-content pb-70">
                                <div
                                  className="blog-post-content"
                                  dangerouslySetInnerHTML={{ __html: props.html }}
                                />
                            </div>
                            <div className="pb-70">
                                <h6>シェア頂けますとうれしいです！</h6>
                                <TwitterShareButton url={articleUrl} style={{width: '33%'}}>
                                    <TwitterIcon size={30} height={"50px"} width={'100%'} style={{backgroundColor:'#00aced'}}/>
                                </TwitterShareButton>
                                <FacebookShareButton url={articleUrl} style={{width: '33%'}} >
                                    <FacebookIcon size={30} height={"50px"} width={'100%'} style={{backgroundColor:'#3b5998'}} />
                                </FacebookShareButton>
                                <LineShareButton url={articleUrl} style={{width: '33%'}} >
                                    <LineIcon size={30} height={"50px"} width={'100%'} style={{backgroundColor:'#00b800'}}  />
                                </LineShareButton>
                            </div>
                            <BlogPrevNext prev={prev} next={next}/>

                            {/*<div className="comments-area">
                             <h3 className="comments-title">2 Comments:</h3>

                             <ol className="comment-list">
                             <li className="comment">
                             <div className="comment-body">
                             <div className="comment-meta">
                             <div className="comment-author vcard">
                             <img src={user1} className="avatar" alt="uu" />
                             <b className="fn">John Jones</b>
                             <span className="says">says:</span>
                             </div>

                             <div className="comment-metadata">
                             <span>April 24, 2019 at 10:59 am</span>
                             </div>
                             </div>

                             <div className="comment-content">
                             <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                             </div>

                             <div className="reply">
                             <Link to="#" className="comment-reply-link">
                             Reply
                             </Link>
                             </div>
                             </div>

                             <ol className="children">
                             <li className="comment">
                             <div className="comment-body">
                             <div className="comment-meta">
                             <div className="comment-author vcard">
                             <img src={user2} className="avatar" alt="uu" />
                             <b className="fn">Steven Smith</b>
                             <span className="says">says:</span>
                             </div>

                             <div className="comment-metadata">
                             <a href="#">
                             <span>April 24, 2019 at 10:59 am</span>
                             </a>
                             </div>
                             </div>

                             <div className="comment-content">
                             <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                             </div>

                             <div className="reply">
                             <a href="#" className="comment-reply-link">Reply</a>
                             </div>
                             </div>

                             <ol className="children">
                             <li className="comment">
                             <div className="comment-body">
                             <div className="comment-meta">
                             <div className="comment-author vcard">
                             <img src={user3} className="avatar" alt="uu" />
                             <b className="fn">Sarah Taylor</b>
                             <span className="says">says:</span>
                             </div>

                             <div className="comment-metadata">
                             <a href="#">
                             <span>April 24, 2019 at 10:59 am</span>
                             </a>
                             </div>
                             </div>

                             <div className="comment-content">
                             <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                             </div>

                             <div className="reply">
                             <Link to="#" className="comment-reply-link">
                             Reply
                             </Link>
                             </div>
                             </div>
                             </li>
                             </ol>
                             </li>
                             </ol>
                             </li>

                             <li className="comment">
                             <div className="comment-body">
                             <div className="comment-meta">
                             <div className="comment-author vcard">
                             <img src={user4} className="avatar" alt="uu" />
                             <b className="fn">John Doe</b>
                             <span className="says">says:</span>
                             </div>

                             <div className="comment-metadata">
                             <a href="#">
                             <span>April 24, 2019 at 10:59 am</span>
                             </a>
                             </div>
                             </div>

                             <div className="comment-content">
                             <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                             </div>

                             <div className="reply">
                             <Link to="#" className="comment-reply-link">
                             Reply
                             </Link>
                             </div>
                             </div>

                             <ol className="children">
                             <li className="comment">
                             <div className="comment-body">
                             <div className="comment-meta">
                             <div className="comment-author vcard">
                             <img src={user1} className="avatar" alt="uu" />
                             <b className="fn">James Anderson</b>
                             <span className="says">says:</span>
                             </div>

                             <div className="comment-metadata">
                             <a href="#">
                             <span>April 24, 2019 at 10:59 am</span>
                             </a>
                             </div>
                             </div>

                             <div className="comment-content">
                             <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                             </div>

                             <div className="reply">
                             <Link to="#" className="comment-reply-link">
                             Reply
                             </Link>
                             </div>
                             </div>
                             </li>
                             </ol>
                             </li>
                             </ol>

                             <div className="comment-respond">
                             <h3 className="comment-reply-title">Leave a Reply</h3>

                             <form className="comment-form">
                             <p className="comment-notes">
                             <span id="email-notes">Your email address will not be published.</span>
                             Required fields are marked
                             <span className="required">*</span>
                             </p>
                             <p className="comment-form-author">
                             <label>Name <span className="required">*</span></label>
                             <input type="text" id="author" placeholder="Your Name*" name="author" required="required" />
                             </p>
                             <p className="comment-form-email">
                             <label>Email <span className="required">*</span></label>
                             <input type="email" id="email" placeholder="Your Email*" name="email" required="required" />
                             </p>
                             <p className="comment-form-url">
                             <label>Website</label>
                             <input type="url" id="url" placeholder="Website" name="url" />
                             </p>
                             <p className="comment-form-comment">
                             <label>Comment</label>
                             <textarea name="comment" id="comment" cols="45" placeholder="Your Comment..." rows="5" required="required"></textarea>
                             </p>
                             <p className="comment-form-cookies-consent">
                             <input type="checkbox" value="yes" name="comment-cookies-consent" id="comment-cookies-consent" />
                             <label>Save my name, email, and website in this browser for the next time I comment.</label>
                             </p>
                             <p className="form-submit">
                             <input type="submit" name="submit" id="submit" className="submit" value="Post A Comment" />
                             </p>
                             </form>
                             </div>
                             </div>*/}
                        </div>
                    </div>


                    {/*<div className="col-lg-3 col-md-12">*/}
                    {/*    <BlogSidebar category={frontmatter.categories[0]} title={frontmatter.title}/>*/}
                    {/*</div>*/}
                </div>
            </div>
        </section>
        </WrapperStyle>
    )
};

export default BlogDetailsContent