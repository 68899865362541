import React from "react"
import {Link} from 'gatsby';
import img11 from '../../assets/images/blog/blog-img11.jpg';
import img12 from '../../assets/images/blog/blog-img12.jpg';

const PrevNext = ({ prev, next }) => {
    return(
      <div className="tracer-post-navigation">
        { prev == null ? null : _link(prev, `prev`)}
        { next == null ? null : _link(next, `next`)}
      </div>
    )
}
export default PrevNext

const _link = ({ fields, frontmatter }, direction) =>{
    return(
      <>
           {(direction === 'prev')
             ? <div className="prev-link-wrapper">
               <div className="info-prev-link-wrapper">
                 <Link to={fields.slug}>
                      <span className="image-prev">
                          <img src={frontmatter.bannerImage.childImageSharp.fixed.srcWebp} alt="bb" />
                          <span className="post-nav-title">Prev</span>
                      </span>

                   <span className="prev-link-info-wrapper">
                                                <span className="prev-title">{frontmatter.title}</span>
                                                <span className="meta-wrapper">
                                                    <span className="date-post">{frontmatter.updatedAt}</span>
                                                </span>
                                            </span>
                 </Link>
               </div>
             </div>
             :
             <div className="next-link-wrapper">
               <div className="info-next-link-wrapper">
                 <Link to={fields.slug}>
                 <span className="next-link-info-wrapper">
                 <span className="next-title">{frontmatter.title}</span>
                 <span className="meta-wrapper">
                 <span className="date-post">{frontmatter.updatedAt}</span>
                 </span>
                 </span>

                 <span className="image-next">
                 <img src={frontmatter.bannerImage.childImageSharp.fixed.srcWebp} alt="bb" />
                 <span className="post-nav-title">Next</span>
                 </span>
                 </Link>
               </div>
             </div>
           }
        </>
    )
}