import styled from 'styled-components';
const DocumentStyle = styled.div` 
	color: #2C343E !important;
	font-size: 17px;
	line-height: initial!important;
	.toc {
	  width: 80%;
	  box-sizing: border-box;
	  padding: 10px;
	  border: 1px solid #888;
	  margin-top: 20px;
	  margin-left: auto;
	  margin-right: auto;
	  margin-bottom: 0;
	}
	a {
	  color: #007bff;
	  text-decoration: none;
	  background-color: transparent;
	}
	h1 {
		font-size: 33px;
	}
	h2 {
		border-left: 6px solid #50C8A7;
		font-weight: bold;
		padding: 7px 0 7px 15px;
		margin: 100px 0 30px;
		font-size: 29px;
	}
	h3 {
	    border-left: 4px solid #d7dbdf;
		margin: 50px 0 20px !important;
		padding: 5px 0 5px 15px !important;
		font-weight: bold;
		font-size: 26px;
	}
	h4 {
		margin: 25px 0 15px !important;
		font-weight: bold;
		font-size: 23px;
	}
	blockquote{
	    text-align: left;
		margin: 1em;
		padding: 25px !important;
		p{
			font-size: 1em !important;
			font-weight: initial;
		}
		::after {
			content:none;
		}
		::before {
			animation: none;
			font-size: 70px;
		}
	}
	ol,ul>li{
		line-height: 2;
		color: #6b6b84;
		font-size: 16px;
	}
	@media only screen and (max-width: 576px) {
	  h1 {
		  font-size: 26px;
		  word-break: initial;
	  }
	  h2 {
	  	margin: 70px 0 30px;
		font-size: 22px;
	  }	
	  .toc {
	  	width: 100%;
	  }
	  
	}
   
`;

export default DocumentStyle