import React from 'react';
import {Link} from 'gatsby';
import {
	FacebookShareButton,
	FacebookIcon,
	LineShareButton,
	LineIcon,
	LinkedinShareButton,
	LinkedinIcon,
	TwitterShareButton,
	TwitterIcon
} from 'react-share';

 const BlogContentHeader = (props) => {
	const {slug, title, tags, author, createdAt, updatedAt} = props;
	const articleUrl = 'https://retach.biz' + slug;

	return (
		<div className="article-footer" style={{
			paddingTop: '10px',
			paddingBottom: '10px',
			marginTop: '10px',
		  color:'#9a9ab4',
		}}>
			{/*<div style={{flex: '100%'}}>*/}
			{/*	*/}
			{/*</div>*/}
			<div className="article-tags">
			  {author}
				{/*<span><i className='bx bx-purchase-tag'></i></span>*/}

				{/*{tags.map(tag => {*/}
				{/*	return <Link to="#">{tag}</Link>*/}
				{/*})}*/}
			</div>

			<div className="article-share">
				<ul style={{listStyle:'none'}}>
				  <li style={{float:'right', marginLeft:'10px'}}>
					<i className='bx bx-calendar'></i>
					<span>公開日</span>
					{createdAt}
				  </li>
				  <li style={{float:'right', marginLeft:'10px'}}>
					<i className='bx bx-calendar'></i>
					<span>更新日</span>
					{updatedAt}
				  </li>
				</ul>
			</div>
		</div>
	)
};

export default BlogContentHeader