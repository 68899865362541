import React from 'react';
import {Link} from 'gatsby';
import BlogBannerStyle from './BlogBanner.style';

const PageBanner = ({pageTitle, homePageUrl, homePageText, activePageText, subDirectories =[]}) => {
    return (
        <BlogBannerStyle>
            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <ul>
                            <li>
                                <Link to={homePageUrl}>
                                    {homePageText}
                                </Link>
                            </li>
                          {subDirectories.length > 0 ? subDirectories.map((x, i) => {
                              return (<li key={i}>
                                <Link to={x.url} >{x.name}</Link>
                              </li>)
                            })
                            : ''}
                            <li>{activePageText}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </BlogBannerStyle>
    )
};

export default PageBanner;