import React from "react"
import { graphql } from "gatsby"
import Navbar from "../components/App/Navbar"
import BlogBanner from '../components/BlogContent/BlogBanner'
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import BlogDetailsContent from '../components/BlogContent/BlogDetailsContent';
import SEO from '../components/App/SEO';
import Helmet from 'react-helmet'
import ProjectStart from '../components/Common/StartProject';
import WrapperStyle from '../pages/instantwin.style';
import ScriptTag from 'react-script-tag';

export default function Template({data, pageContext }) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html} = markdownRemark;
  return (
      <Layout>
          <SEO
            title={frontmatter.title}
            desc={frontmatter.description}
            banner={frontmatter.bannerImage.childImageSharp.fluid.src}
            pathname={frontmatter.slug}
            type={'Article'}
            shortName={frontmatter.title}
            createdAt={frontmatter.createdAt}
            slug={frontmatter.slug}
          />
        <Navbar />
        <BlogBanner
            pageTitle={frontmatter.title}
            homePageText="Home"
            homePageUrl="/"
            activePageText={frontmatter.title}
            subDirectories={[{
                    url: '/blog',
                    name: 'ブログ'
            }]}
        />
        <BlogDetailsContent
            html={html}
            frontmatter={frontmatter}
            prev={pageContext.prev}
            next={pageContext.next}
        />
        <ScriptTag  type="text/javascript" src="https://retach.biz/scripts/promotionCall.js"/>
        <ScriptTag  type="text/javascript" src="https://platform.twitter.com/widgets.js"/>
        <ScriptTag  type="text/javascript" src="//cdn.iframe.ly/embed.js" charSet="utf-8"/>
        <ProjectStart />
        <Footer />
      </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        createdAt(formatString: "YYYY-MM-DD")
        updatedAt(formatString: "YYYY-MM-DD")
        slug
        title
        tags
        categories
        author
        description
        bannerImage {
            childImageSharp {
                fixed(width: 350, height: 300) {
                  ...GatsbyImageSharpFixed
                }
                fluid(maxWidth: 1000, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
      }
    }
  }
`;